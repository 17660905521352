export default {
  moreIcon: 'icon-open-in-new',
  iconCaretLeft: 'icon-caret-left',
  iconCaretRight: 'icon-caret-right',
  phoneIcon: 'icon-chatbox_phone',
  emailIcon: 'icon-chatbox_mail',
  socialNetworkIcon: 'icon-chatbox_account',
  openInNew: 'icon-open-in-new',
  thumbDown: 'icon-thumb-down',
  thumbUp: 'icon-thumb-up',
  form: 'icon-loading',
  database: 'icon-database',
  printer: 'icon-printer',
  email: 'icon-email-send',
  shield: 'icon-shield-lock',
  submit: 'icon-send',
  close: 'icon-close',
  collapse: 'icon-minimize',
  expand: 'icon-maximize',
  fontIncrease: 'icon-font-plus',
  fontDecrease: 'icon-font-minus',
  minimize: 'icon-chatbox_reduire',
  more: 'icon-chatbox_option',
  dots: 'icon-dots',
  iconConversation: 'icon-chatbox_conversation',
  iconContact: 'icon-chatbox_contact',
  microphon: 'icon-microphon',
  pause: 'icon-pause',
  play: 'icon-play',
  replay: 'icon-replay',
  stop: 'icon-stop',
  power: 'icon-power',
  timer: 'icon-timer',
  checkCircle: 'check-circle',
  time: 'icon-time',
};
